// Service
import service from "../../services/Api/Service";

// Constants
import { CRUD_ACTION } from "../../constants/ActionKeys";

// General
import { request, success, failure } from ".";

function getList(url, data, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.VIEW_REQUEST, { key }));

      // API Calling
      const response = await service.getService(url, data);

      if (response) {
        dispatch(success(CRUD_ACTION.VIEW_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.VIEW_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.VIEW_FAILURE, { key }));
      throw error;
    }
  };
}

function viewRequest(url, data, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.VIEW_REQUEST, { key }));

      // API Calling
      const response = await service.postService(url, data);

      if (response) {
        dispatch(success(CRUD_ACTION.VIEW_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.VIEW_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.VIEW_FAILURE, { key }));
      throw error;
    }
  };
}

function addRequest(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.ADD_REQUEST, { key }));

      // API Calling
      const response = await service.postService(url, data, opt);
      if (response) {
        dispatch(success(CRUD_ACTION.ADD_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.ADD_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.ADD_FAILURE, { key }));
      throw error;
    }
  };
}

function updateRequest(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.EDIT_REQUEST, { key }));

      // API Calling
      const response = await service.putService(url, data, opt);
      if (response) {
        dispatch(success(CRUD_ACTION.EDIT_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.EDIT_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.EDIT_FAILURE, { key }));
      throw error;
    }
  };
}

function deleteRequest(url, data, opt, key) {
  return async (dispatch) => {
    try {
      dispatch(request(CRUD_ACTION.DELETE_REQUEST, { key }));

      // API Calling
      const response = await service.deleteService(url, data, opt);
      if (response) {
        dispatch(success(CRUD_ACTION.DELETE_SUCCESS, { key, response }));
      } else {
        dispatch(failure(CRUD_ACTION.DELETE_FAILURE));
      }

      return response;
    } catch (error) {
      dispatch(failure(CRUD_ACTION.DELETE_FAILURE, { key }));
      throw error;
    }
  };
}

function updateState(data, key) {
  return async (dispatch) => {
    dispatch(request(CRUD_ACTION.UPDATE_STATE, { data, key }));
  };
}

function storeComplianceData(data, key) {
  return async (dispatch) => {
    dispatch(request(CRUD_ACTION.UPDATE_COMPLIANCE_DATA, { data, key }));
  };
}

export {
  getList,
  viewRequest,
  addRequest,
  updateRequest,
  deleteRequest,
  updateState,
  storeComplianceData,
};
