import React from "react";
import parse, { domToReact } from "html-react-parser";

export const getValue = (key, collection) => {
  for (const i in collection) {
    const res = collection[i];
    if (res.key == key) return res.value;
  }
};

export const NumberToAlphabet = (num) => {
  let result = "",
    value;

  while (num > 0) {
    value = (num - 1) % 26;
    result = String.fromCharCode(65 + value) + result;
    num = ((num - value) / 26) | 0;
  }

  return result.toLowerCase();
};

export const AlphabetToNumber = (str) => {
  let result = 0;
  const length = str?.length;

  for (let i = 0; i < length; i++) {
    const char = str[i];
    const value = char.charCodeAt(0) - "a".charCodeAt(0) + 1;
    result = result * 26 + value;
  }

  return result;
};

export const RemoveTags = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  // replace space code to space character
  str = str.replaceAll("&nbsp;", " ");

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "")?.split(":");
  // return str.replace(/<br\s*[\/]?>/gi, "\n");
};

export const parseHtmlMessage = (response, No = false) => {
  const result = parse(response, options);
  return result;
};

export const options = {
  replace: ({ attribs, children, name }) => {
    if (!attribs) {
      return;
    }

    if (attribs && name === "br") {
      return <></>;
    }
    if (attribs && name === "li") {
      return (
        <li>
          <span> &#8226; {domToReact(children, options)}</span>
        </li>
      );
    }
    if (attribs && name === "p") {
      return <> {domToReact(children, options)}</>;
    }
  },
};
