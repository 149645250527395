export const API_URLS = {
  LOGIN: "/user/login",
  CHANGE_PASSWORD: "/user/changePassword",
  REFRESH_TOKEN: "/meta/authenticate",
  FORGET_PASSWORD: "/user/forgotPassword",
  FEEDYARD_LIST: "/feedlot",
  AUDIT_LIST: "/audit",
  ADD_EDIT_AUDIT: "/audit/processOptimized",
  ADD_EDIT_AUDIT_SECTION: "/auditsection/",
  ADD_EDIT_AUDIT_TABLE: "/audit-table/process/",
  COPY_AUDIT_SECTION: "/audit/:id/component/copymove",
  USER_LIST: "/user",
  ACTIVITIES_LIST: "/activity",
  TAGS_LIST: "/activitytag/",
  TAG_DELETE: "/activitytag/delete/",
  ADD_DOC: "/document/create",
  MENU_ITEMS: "/menuitem/2",
  PREVIOUS_AUDIT_CHECK: "/auditassignment/check_previous_audit",
  ACTIVITY_FIELD_TYPE: "/menuitem/8",
  PERMISSIONS_TYPES: "/menuitem/9",
  REQUIRED_FREQUENCY: "/menuitem/10",
  CALENDAR_FRQUENCY: "/menuitem/11",
  ACTIVITY_TAGS: "/activitytag/",
  ACTIVITY: "/activity/",
  VIEW_ACTIVITY: "/activity/",
  DEPRECATE: "/deprecate",
  CREATE_ACTIVITY: "/activity/create",
  UPDATE_ACTIVITY: "/activity/update",
  UPDATE_FEEDYARD_ACTIVITY: "/feedlotactivity",
  FEEDYARD_RANK_LIST: "/feedlot/allFeedlotsByRank",
  ADD_ROLE: "/menuitem/userrole",
  DOWNLOAD_BULK_USERS:
    "/user/bulk_users_excel/bulk_add_users_template.xlsx?dir_name=template",
  ADD_BULK_USERS: "/user/bulk_add_users",
  UPDATE_ACTIVITY_STATUS: "/activity/changeStatus",
  UNLOCK_USER: "/user/unlock",
  ADD_TRAINER: "/user/trainer",
  ADD_DEFAULT: "/activity/default",
  ADD_AUDITOR: "/user/auditor",
  ADD_FEEDLOTMANAGER: "/user/feedlotmanager",
  ADD_REVIEWER: "/user/reviewer",
  ADD_FEEDYARD: "/feedlot",
  EDIT_FEEDYARD: "/feedlot",
  USER_BULK_UNLOCK: "/user/bulk_unlock",
  USER_CHANGE_STATUS: "/user/changeStatus",
  VIEW_COMPLIANCE: "/activity/compliance/:id",
  CONSUMED_FEEDYARDS: "/activity/usage/:id",
  FEEDYARD_RANK_UPDATE: "/feedlot/rank/update/",
  FEEDYARD_ACTIVITIES: "/feedlotactivity/:id/admin_activities",
  FEEDYARD_USERS_ROLES: "/menuitem/userrole?criteria=feedlot",
  DOWNLOAD_BULK_USER_EXCEL: "/user/bulk_users_excel",
  RESTORE_ACTIVITY: "/activity/:id/restore",
  DOWNLOAD_BULK_FEEDYARD_USERS:
    "/user/bulk_users_excel/bulk_add_feedyard_users_template.xlsx?dir_name=template",
  UPDATE_FEEDYARD_STATUS: "/feedlotactivity/:id/changeStatus",
  DOWNLOAD_ACTIVITY_TRANSLATIONS:
    "/feedlotactivity/:id/activities_for_translation",
  IMPORT_ACTIVITY_TRANSLATIONS:
    "/feedlotactivity/:id/import_activity_translations",
  DOWNLOAD_REPORT: "/feedlotactivity/report",
  FEEDYARD_NOTIFICATIONS: "/feedlot/:id",
  IMPORT_ACTIVITIES: "/feedlotactivity/:id/available",
  SAVE_IMPORT_ACTIVITIES: "/feedlotactivity/:id/import",
  VIEW_FEEDYARD_ACTIVITY: "/feedlotactivity/:id/activity/:number",
  RESET_PASSWORD: "/user/resetPass",
  FIELD_RULE: "/fieldrule",
  DELETE_FIELD_RULE: "/fieldrule/:id",
  FEEDYARD_STATE: "/menuitem/3",
  RESET_PASSWORD: "/user/resetPass",
  IMPORT_ACTIVITY_IN_FEEDYARD: "/feedlot/importActivities",
  ADD_NOTIFICATION_LEVEL: "/fieldrule/notification_level/activity/:id",
  DASHBOARD_NOTIFICATION_ADMIN: "/notification/admin",
  DASHBOARD_FEEDLOT_STATS: "/feedlotstatistics",
  DASHBOARD_AUDIT_STATS: "/audit/statistics",
  FEEDLOT_EXCEL_DOWNLOAD: "/feedlotactivitysubmission/excel/:fileName",
  FEEDLOT_REPORT_DOWNLOAD: "/feedlotactivitysubmission/report/:fileName",
  COPY_ACTIVITY: "/feedlotactivity/copy",
  SEND_FEEDYARD_ACTIVITY_BIN: "/feedlotactivity/:id/activity/:number/deprecate",
  RESTORE_FEEDYARD_ACTIVITY: "/feedlotactivity/:id/activity/:number/restore",
  ADD_ACTIVITY_INSTANCE: "/feedlotactivityinstance",
  DELETE_ACTIVITY_INSTANCE: "/feedlotactivityinstance/delete",
  VIEW_SUBMISSION_ACTIVIY:
    "/feedlotactivity/:feedlot_id/activity/id/:feedlot_activity_id?include_fields=1&include_revisions=1",
  VIEW_SUBMISSION_EXCEL_REPORT: "/feedlotactivitysubmission/excel",
  VIEW_SUBMISSION_PDF_REPORT: "/feedlotactivitysubmission/report",

  VIEW_SUBMISSION_HISTORY: "/feedlotactivitysubmission/history/:id",
  FEEDLOT_USERS: "/feedlot/:id/feedlotusers",
  VIEW_SUBMISSION_REVISION: "/feedlotactivity/:id/activity/:activity_number",
  CHECK_TOKEN: "/user/checkToken/:token",
  VIEW_AUIDT: "/audit/:id",
  VIEW_AUDIT_SECTION: "/auditsection/:id",
  MODIFY_AUDIT_SECTION_QUESTION: "/auditsectionquestion/",
  DELETE_AUDIT_SECTION_QUESTION: "/auditsectionquestion/:id",
  DOCUMENTS: "/document/",
  VIEW_SUB_ATTACHMENT: "/feedlotactivityattachment/file/:file",
  SAVE_ANSWER_TEMPLATE: "/auditanswertemplate/",
  DELETE_ANSWER_TEMPLATE: "/auditanswertemplate/:id",
  DELETE_ANSWER_TEMPLATE_FIELD: "/auditanswertemplate/field/:id",
  DOWNLOAD_AUDIT_SUMMARY: "/auditassignment/:id/summary/pdf",
  ASSIGN_FEEDYARD: "/auditassignment/",
  UNASSIGN_FEEDYARD: "/auditassignment/:id",
  REVERT_ASSIGNMENT_STATUS: "/auditassignment/:id/revert/:status",
  COPY_MOVE_FIELD: "/audit/:id/component/copymove",
  SAVE_SECTION_QUESTION_SEQUENCE: "/auditsectionquestion/order/",
  ADD_ROW_SUMMARY_TEMPLATE: "/audit-table/processRow/",
  AUDIT_TABLE: "/audit-table/:id",
  AUDIT_TABLE_ROW: "/audit-table/rows/:id",
  AUDIT_COPY: "/audit/copyOfOptimized/:id",
  VIDEO_TABLE: "/training/?platform=2",
  DELETE_VIDEO: "/training/:id",
  VIDEO_ROLE: "/menuitem/userrole?criteria=training",
  VIDEO_CATEGORY: "/menuitem/22",
  ADD_VIDEO: "/training/",
  GET_VIDEO: "/training/get/:id",
  SAVE_AUDIT_TABLE_TEMPLATE: "/audit-table/update-row-sequence",
  ACTIVITIES_INCLUDED: "/feedlotactivity/:id/forcomplaince",
  COMPLIANCE_REPORT_FX: "/feedlotactivityperformance/compliancereport/",
  SUMMARY_BY_FEEDYARD_REPORT: "/auditreport/summary/",
  SCORE_OVERTIME_REPORT: "/auditreport/overtime/",
  APP_VIDEO_DATA: "/app_training_videos/1",
  SCHEDULE_CUSTOM_REPORT: "/schedulecustomreport",
  DELETE_SCHEDULE_CUSTOM_REPORT: "/schedulecustomreport/:id",
  SCHEDULE_CUSTOM_EXCEL_REPORT: "/schedulecustomreport/excel/",
  AUDIT_STATISTICS: "/audit/statistics",
  TOP_SCORE_FEEDYARD: "/auditreport/top-five-feedyards",
  BOTTOM_SCORE_FEEDYARD: "/auditreport/bottom-five-feedyards/",
  INTERNAL_VS_EXTERNAL: "/auditreport/internalVsExternal/",
  FEEDLOT_ACTIVITY_PERFORMANCE_BENCHMARK:
    "/feedlotactivityperformance/benchmark",
  GET_REPORT_JOB: "/report_job/:id",
  DOWNLOAD_REPORT_FILE: "/report_job/download",
  SOP: "/sop",
  ADMIN_SOP: "/sop",
  ADD_EDIT_SOP: "/sop",
  REMOVE_SOP: "/sop/:id",
  SAVE_NC_CA_URL: "/nc-ca/",
  GET_QUESTION_NC_CA_URL: "/nc-ca/:questionId",
  COPY_NC_CA_URL: "/nc-ca/copy",
};
