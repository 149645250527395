export const REDUX_STATES = {
  DEFAULT_KEY: "listing" + new Date().getTime(),
  DEFAULT_DROPDOWN_KEY: "dropdown" + new Date().getTime(),
  DEFAULT_ADD_KEY: "add" + new Date().getTime(),
  DEFAULT_VIEW_KEY: "view" + new Date().getTime(),
  DEFAULT_EDIT_KEY: "edit" + new Date().getTime(),
  DEFAULT_DEL_KEY: "delete" + new Date().getTime(),
  LOADING: "_loading",
  ERROR: "_error",
  ADD: "_add",
  VIEW: "_view",
  EDIT: "_edit",
  DATA: "_data",
  ACCORDIAN_KEY: "accordianNo",
  ALL_AUDIT_SECTIONS: "allAuditSections",
  ALL_AUDITS: "allAudits",
  ALL_USERS: "allUsers",
  ALL_FEEDYARDS: "allFeedyards",
  PREVIOUS_AUDIT_EXISTS: "previousAuditExist",
  ALL_ACTIVITIES: "allActivities",
  ALL_TAGS: "allTags",
  ADD_TAG: "addTag",
  ADD_EDIT_AUDIT: "addEditAudit",
  PUBLISH_AUDIT: "publishAudit",
  AUDIT_COPY: "auditCopy",
  ADD_EDIT_AUDIT_SECTION: "addEditAuditSection",
  ADD_EDIT_AUDIT_TEMPLATE: "addEditAuditTemplate",
  ADD_EDIT_AUDIT_TABLE: "addEditAuditTable",
  MENU_ITEMS: "tabs",
  CHANGE_PASSWORD: "changePassword",
  ACTIVITY_FIELD_TYPES: "activity_field_types",
  PERMISSIONS_TYPES: "permissions",
  REQUIRED_FREQUENCY: "frequency",
  FRQUENCY_CALENDAR: "frequency_calendar",
  ACTIVITY_TAGS: "activity_tags",
  ACTIVITY_DETAIL: "activityDetail",
  CREATE_ACTIVITY: "createActivity",
  UPDATE_ACTIVITY: "updateActivity",
  UPDATE_FEEDYARD_ACTIVITY: "update_feedyard_activity",
  DEPRECATE_ACTIVITY: "deprecateActivity",
  RESTORE_ACTIVITY: "restoreActivity",
  ROLE: "addRole",
  TRAINER: "addtrainer",
  DEFAULTACTIVIT: "adddefault",
  FEEDLOTMANAGER: "addfeedlotmanager",
  FEEDYARD_LIST: "feedyardList",
  FEEDYARD_RANK_LIST: "feedyardRankList",
  AUDITOR: "addAuditor",
  ADD_AUDITOR: "addAuditor",
  ADD_REVIEWER: "addReviewer",
  EDIT_USER_GET: "editUser_get",
  EDIT_USER_POST: "editUser_post",
  DOWNLOAD_BULK_USERS: "download_bulk_user",
  ADD_BULK_USERS: "add_bulk_user",
  UPDATE_ACTIVITY_STATUS: "updateActivityStatus",
  UNLOCK_USER: "unlock_user",
  ADD_FEEDYARD: "addFeedyard",
  EDIT_FEEDYARD: "editFeedyard",
  USER_BULK_UNLOCK: "unlock_bulk_users",
  USER_CHANGE_STATUS: "user_change_status",
  VIEW_COMPLIANCE: "view_compliance",
  CONSUMED_FEEDYARDS: "consumed_feedyards",
  FEEDYARD_ACTIVITIES: "feedyard_activities",
  DOWNLOAD_USERS_ERROR_REPORT: "download_error_report",
  ADD_ASSOCIATE_USER: "add_associiate_user",
  DISASSOCIATE_USER: "disassociate_user",
  DOWNLOAD_EXCEL_REPORT: "download_excel_report",
  FEEDYARD_NOTIFICATIONS: "feedyard_notifications",
  FEEDYARD_ACTIVITY_DETAIL: "feedyard_activity_detail",
  IMPORT_ACTIVITIES: "import_activities",
  SAVE_IMPORT_ACTIVITIES: "save_import_activities",
  ADD_FIELD_RULE: "add_field_rule",
  DELETE_FIELD_RULE: "delete_field_rule",
  IMPORT_ACTIVITIES_IN_FEEDYARD: "save_importActivites_feedyard",
  FEEDYARD_STATE: "feedyard_state",
  RESET_PASSWORD: "reset_password",
  ADD_NOTIFICATION_LEVEL: "add_notification_level",
  ADD_ACTIVITY_INSTANCE: "add_activity_instance",
  DELETE_ACTIVITY_INSTANCE: "delete_activity_instance",
  DASHBOARD_NOTIFICATION_ADMIN: "dashboard_notification_admin",
  DASHBOARD_FEEDLOT_STATS: "dashboard_feedlot_stats",
  DASHBOARD_AUDIT_STATS: "dashboard_audit_stats",
  FEEDLOT_EXCEL_DOWNLOAD: "feedlot_excel_download",
  FEEDLOT_REPORT_DOWNLOAD: "feedlot_report_download",
  VIEW_SUBMISSION_ACTIVIY: "view_submission_activity",
  VIEW_SUBMISSION_EXCEL_REPORT: "view_submission_excel_report",
  VIEW_SUBMISSION_PDF_REPORT: "view_submission_pdf_report",
  VIEW_SUBMISSION_HISTORY: "view_submission_history",
  FEEDLOT_USERS: "feedlot_users",
  VIEW_SUBMISSION_REVISION: "view_submission_revisions",
  CHECK_TOKEN: "check_token",
  VIEW_AUDIT: "view_audit",
  SECTION_QUESTIONS: "sectionQuestions",
  SECTION_QUESTIONS_ARRAY: "sectionQuestionsArray",
  AUDIT_SECTION: "audit_Section",
  AUDIT_SECTION_QUESTION: "audit_section_question",
  DELETE_AUDIT_SECTION_QUESTION: "delete_audit_section_question",
  SOP_DOCUMENTS: "sop",
  VIEW_SUB_PDF: "view_sub_pdf",
  SAVE_ANSWER_TEMPLATES: "save_answer_templates",
  DLELETE_ANSWER_TEMPLATE: "delete_answer_template",
  VIEW_SUB_IMG: "view_sub_img",
  DOWNLOAD_AUDIT_SUMMARY: "download_audit_summary",
  ASSIGN_FEEDYARD: "assign_feedyard",
  UNASSIGN_FEEDYARD: "unassign_feedyard",
  COPY_MOVE_FIELDS: "copyMoveFields",
  AUDIT_SECTION_DETAIL: "audit_section_detail", // for points validation
  SAVE_SECTION_QUESTION_ORDER: "section_question_order",
  ADD_EDIT_ROW_SUMMARY_TEMPLATE: "add_edit_row_summary_template",
  ADD_ROW_SUMMARY_TEMPLATE_HEADING: "add_row_summary_heading",
  ADD_ROW_SUMMARY_TEMPLATE_NOTE: "add_row_summary_note",
  AUDIT_TABLE_DELETE: "audit_table_delete",
  AUDIT_TABLE_DELETE_ROW: "audit_table_delete_row",
  VIDEO_TABLE: "video_table",
  DELETE_VIDEO: "delete_video",
  VIDEO_ROLE: "video_role",
  VIDEO_CATEGORY: "video_category",
  ADD_VIDEO: "add_video",
  GET_VIDEO: "get_video",
  SAVE_TABLE_TEMPLATE_ORDER: "save_table_template_order",
  FOR_COMPLIANCE: "for_compliance",
  DOWNLOAD_SUMMARY_SCORE_REPORT: "summary_score_report",
  APP_VIDEO_DATA: "app_video_data",
  ALL_SCHEDULE_CUSTOM_REPORT: "schedule_custom_reports",
  ADD_SCHEDULE_REPORT: "add_schedule_report",
  DELETE_SCHEDULE_REPORT: "delete_schedule_report",
  EXCEL_SCHEDULE_REPORT_DOWNLOAD: "excel_schedule_report",
  AUDIT_STATISTICS: "audit_statistic",
  TOP_SCORE_FEEDYARD: "top_score_feedyard",
  BOTTOM_SCORE_FEEDYARD: "bottom_score_feedyard",
  INTERNAL_VS_EXTERNAL: "internal_vs_external",
  COMPLIANCE_REPORTS: "compliance_reports",
  COMPLIANCE_REPORTS_DATA: "compliance_report_data",
  COMPLIANCE_FORM_DATA: "compliance_form_data",
  ADD_EDIT_SOP: "addEditSop",
  ALL_SOPS: "allSops",
  REMOVE_SOP: "removeSop",
  QUESTION_NCS_CAS_LIST: "questionNcsCasList",
  SAVE_NCS_CAS: "saveNcsCas",
  DOWNLOAD_ACTIVITY_TRANSLATIONS_STATUS: "downloadActivityTranslationsStatus",
  IMPORT_ACTIVITY_TRANSLATIONS_STATUS: "importActivityTranslationsStatus",
  UPDATE_AUDIT_ASSIGNMENT_STATUS: "update_audit_assignment_status",


};
