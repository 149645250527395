/*
  Dropdown
*/

import React from "react";

// Antd
import { Select, Form, Tooltip } from "antd";
// translation
import { useTranslation } from "react-i18next";

// Style
import "./style.scss";

const { Option } = Select;

function Dropdown({
  name,
  label,
  showSearch,
  placeholder,
  options,
  onChange,
  defaultValue,
  allowClear,
  disabled,
  required,
  loading,
  onKeyUp,
  onKeyDown,
  validator,
  validateTrigger,
  notwrapInForm,
  tooltip,
  message,
  mode,
  maxTagCount,
  extra,
}) {
  const { t } = useTranslation();
  // const sortBy = [{ prop:'text', direction: 1 }];
  // const sortedOption = sortArray(options, sortBy);
  const sortedOption = options;

  // Validations rules

  // let rules = [
  //   { required: required, message: message || "Required" },
  //   ...(validator || []),
  // ];
  let rules = [
    { required: required, message: message ? message : t("Required") },
  ];

  if (!!validator) {
    rules.push({ validator: validator });
  }

  const renderMaxTagPlaceholder = (omittedValues) => (
    <Tooltip title={omittedValues.map((val) => val.label).join(", ")}>
      & +{omittedValues.length} others
    </Tooltip>
  );

  const DropdownList = (
    <Select
      getPopupContainer={(trigger) => trigger.parentNode}
      showSearch={showSearch === false ? false : true}
      allowClear={allowClear === false ? false : true}
      defaultValue={defaultValue}
      placeholder={placeholder}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onKeyDown={onKeyDown}
      disabled={disabled}
      loading={loading}
      optionFilterProp="title"
      suffixIcon={<i className="icon-caret-down"></i>}
      showArrow={!disabled}
      mode={mode}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={renderMaxTagPlaceholder}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
    >
      {sortedOption &&
        sortedOption?.map((data, index) => {
          return (
            <Option key={index} title={data.name} value={data.key}>
              {data.value}
            </Option>
          );
        })}
    </Select>
  );

  return !!notwrapInForm ? (
    <>{DropdownList}</>
  ) : (
    <Form.Item
      name={name}
      label={label}
      validateTrigger={validateTrigger || "onBlur"}
      rules={rules}
      tooltip={tooltip}
      extra={extra || null}
    >
      {DropdownList}
    </Form.Item>
  );
}

export default Dropdown;
